import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Input, Typography, Radio, message } from 'antd';
import { PageTitle, SubmitButton, ImageUploader } from '../../../../components'
import { SentralModel } from '../../../../models/SentralModel'
import { useHistory, useParams } from 'react-router-dom'
import { ImageViewer } from '../../../../components/ImageViewer'

const { TextArea } = Input
const { Text } = Typography

const Create = () => {
    const history = useHistory();
    const { testId } = useParams();
    const [saving, setSaving] = useState();
    const [form] = Form.useForm();
    const [questions, setQuestions] = useState([]);

    const [selectedId, setSelectedId] = useState(null);

    const fetchQuestion = () => {
        SentralModel.list('TestMultipleChoice', { filter: [['test_id', testId]], orderBy: 'question_number', order: 'asc' }).then(res => {
            if (res.data.length === 0) {
                let questions = [];
                for (var i = 1; i <= 50; i++) {
                    questions.push({
                        number: i,
                        question: '',
                        answers: [
                            { alphabet: 'A', answer: null, correct: 0 },
                            { alphabet: 'B', answer: null, correct: 0 },
                            { alphabet: 'C', answer: null, correct: 0 },
                            { alphabet: 'D', answer: null, correct: 0 },
                            { alphabet: 'E', answer: null, correct: 0 }
                        ]
                    });
                }
                setQuestions(questions)
            } else {
                let questions = [];
                res.data.forEach((el, key) => {
                    let answers = []
                    let alphabet = ["A", "B", "C", "D", "E"]
                    el.answer.forEach((item, i) => {
                        answers.push({
                            alphabet: alphabet[i],
                            answer: item.answer,
                            correct: item.is_correct
                        });

                        form.setFieldsValue({
                            [`answer[${key}][${i}]`]: item.answer,
                            [`correct[${key}][${i}]`]: item.is_correct,
                        })
                    })

                    questions.push({
                        id: el.test_multiple_choice_id,
                        number: el.question_number,
                        question: el.question,
                        answers: answers,
                        attachment: el.attachment
                    });

                    form.setFieldsValue({
                        [`question[${key}]`]: el.question,
                        [`attachment[${key}]`]: el.attachment,
                    })
                })
                setQuestions(questions)
            }
        }).finally(() => {
        })
    }

    const submitQuestion = (v) => {
        let questions = [];
        for (var i = 1; i <= 50; i++) {

            let answers = [];
            for (var a = 0; a < 5; a++) {
                answers.push({
                    answer: form.getFieldValue(`answer[${i - 1}][${a}]`),
                    correct: form.getFieldValue(`correct[${i - 1}][${a}]`) === true ? 1 : 0
                });
            }

            questions.push({
                test_id: testId,
                question_number: i,
                question: form.getFieldValue(`question[${i - 1}]`),
                answers: answers,
            });
        }

        setSaving(true)
        let payload = {
            test_id: testId,
            question: questions
        }
        // SentralModel.action('Test', 'saveMultipleChoice', payload, 0).then(() => {
        //     message.destroy()
        //     message.success('Test multiple choice saved', 1)
        //     setSaving(false)
        //     setTimeout(() => {
        //         history.push('/management/test/multiple-choice');
        //     }, 1000);
        // }).catch((err) => {
        //     message.destroy()
        //     message.error(err.message, 2)
        //     setSaving(false)
        // })

        console.log(payload)
    }

    const fileUploaded = (v) => {
        setSaving(true);

        let payload = {
            test_multiple_choice_id: selectedId,
            attachment: v.message
        }

        SentralModel.action('Test', 'uploadImage', payload, null).then((res) => {
            fetchQuestion();
            message.success('Image uploaded', 2);
        }).finally(() => {
            setSaving(false);
        })
    }

    useEffect(() => {
        fetchQuestion()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card className="content-container">
            <PageTitle title="Test Multiple Choice" breadcrumbs={[['Test Multiple Choice', '/management/test/multiple-choice'], ['Create']]}></PageTitle>
            <Form form={form} size="middle" layout="vertical" onFinish={submitQuestion}>
                <Row justify="center">
                    {
                        questions.map((item, key) => (
                            <Col span={20} className="mb-2">
                                <Row justify="space-between">
                                    <Col span={24}>
                                        <Form.Item name={`question[${key}]`} label={'Question No .' + item.number} rules={[{ required: true }]}>
                                            <TextArea autoSize={{ minRows: 2 }} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row span={12}>
                                    <Form.Item label="Attachment">
                                        <div onClick={() => setSelectedId(item.id)}>
                                            <ImageUploader folder="test" onUploaded={(v) => fileUploaded(v)} view={true} />
                                        </div>
                                    </Form.Item>

                                    {
                                        item.attachment !== null ?
                                            <div style={{ width: 250, height: 150, maxWidth: '100%', marginLeft: 20, marginBottom: 25, marginTop: 25 }}>
                                                <ImageViewer previewable={true} src={process.env.REACT_APP_API_FILE + "/" + item.attachment} alt={`attachment-${key}`} />
                                            </div>
                                            : null
                                    }
                                </Row>

                                <Row>
                                    <Col span={24}>
                                        <Card title={
                                            <div className="full-width">
                                                <Text strong style={{ float: 'left' }}>Answer</Text>
                                            </div>}>

                                            <table className="table-item" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Answer</th>
                                                        <th>Correct</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        item.answers.map((el, index) => (
                                                            <tr key={'item' + index}>
                                                                <td>
                                                                    {el.alphabet}
                                                                </td>
                                                                <td>
                                                                    <Form.Item name={`answer[${key}][${index}]`} rules={[{ required: true }]}>
                                                                        <Input placeholder="Answer" />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item valuePropName="checked" name={`correct[${key}][${index}]`}>
                                                                        <Radio value="1">Is this choice correct ?</Radio>
                                                                    </Form.Item>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        ))
                    }
                    <Col span={20} className="text-right">
                        <SubmitButton loading={saving} />
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}

export default Create;
