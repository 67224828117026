import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography } from 'antd'
import { PageTitle, Spinner } from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import { useParams } from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';

const { Text } = Typography

const Detail = () => {
    const { requestId } = useParams()
    const [resign, setResign] = useState(null)

    const fetchResign = (id) => {
        SentralModel.get('SubmissionResign', {}, id).then((res) => {
            setResign(res.data)
        })
    }

    useEffect(() => {
        fetchResign(requestId)
    }, [requestId]);

    const statusUpdated = () => {
        setResign(null)
        fetchResign(requestId)
        // window.location.reload();
        window.history.back();
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Resign Request" breadcrumbs={[['Submission List', '/submission-list'], ['Detail']]}></PageTitle>
            </div>
            {
                (resign) ?
                    <div>
                        <DetailHeader
                            name={resign.employee.name}
                            date={resign.created_at}
                            status={resign.status} />
                        <Card bordered className="mb-4">
                            <Text strong>RESIGN DETAIL</Text>
                            <Row className="mb-4 mt-1">
                                <Col span={12}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <th>Name</th><td>{resign.employee.name}</td>
                                            </tr>
                                            <tr>
                                                <th>Division</th><td>{resign.employee.division.division_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Position</th><td>{resign.employee.position.position_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Exit Date</th><td>{resign.exit_date}</td>
                                            </tr>
                                            <tr>
                                                <th>Reason</th><td>{resign.reason}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered>
                            <ApprovalLog category="RESIGN"
                                requestId={requestId}
                                approvers={resign.approver}
                                onSubmitted={() => statusUpdated()} />
                        </Card>
                    </div> : <Spinner />
            }
        </Card>
    );
}

export default Detail;
