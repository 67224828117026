import { useState, useEffect } from 'react'
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, TimePicker, message } from 'antd'
import { PageTitle } from '../../../components'
import { useParams, useHistory } from 'react-router-dom'
import moment from 'moment'
import { SentralModel } from '../../../models/SentralModel'

const Index = () => {
    const history = useHistory();
    const [form] = Form.useForm()
    const { id } = useParams()
    const [loaded, setLoaded] = useState(true)

    const fetchIncidentReport = (id) => {
        setLoaded(false)
        SentralModel.action("ItInfra", "getIncidentReportById", null, id).then((res) => {
            form.setFieldsValue({
                ...res.data.incident_report,
                incident_date: moment(res.data.incident_report.incident_date),
                incident_time: moment(res.data.incident_report.incident_time, "hh:mm"),
                incident_ceklis: res.data.incident_ceklis,
            })
            setLoaded(true)
        })
    }

    useEffect(() => {
        if (parseInt(id) !== 0) {
            fetchIncidentReport(id)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const submitIncident = (v) => {
        let payload = {
            ...v,
            incident_date: moment(v.incident_date).format("YYYY-MM-DD"),
            incident_time: moment(v.incident_time).format("HH:mm")
        }

        SentralModel.action("ItInfra", "saveItIncident", payload, id).then((res) => {
            message.success("Success Submit Report")
            history.push("/it-infra/incident-report")
        }).catch((err) => {
            message.warning("Failed Submit Report")
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Create Incident Report" breadcrumbs={[['Incident Report', '/it-infra/incident-report'], ['Create']]}></PageTitle>
                <Card title="Form Incident Report">
                    {
                        loaded &&
                        <Form layout='vertical' onFinish={submitIncident} form={form}>
                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Form.Item name="incident_date" label="Tanggal Insiden" rules={[{ required: true }]}>
                                        <DatePicker style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item name="incident_time" label="Waktu Insiden" rules={[{ required: true }]}>
                                        <TimePicker style={{ width: '100%' }} format="hh:mm" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Form.Item name="incident_type" label="Jenis Insiden" rules={[{ required: true }]}>
                                        <Select
                                            placeholder="Pilih Jenis Insiden"
                                            options={[
                                                { value: "KELUHAN_PELANGGAN", label: "Keluhan Pelanggan" },
                                                { value: "INSIDEN_K3", label: "Insiden K3" },
                                                { value: "INSIDEN_KEAMANAN_INFORMASI", label: "Insiden Keamanan Informasi" },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item name="incident_ceklis" label="Ceklis Insiden" rules={[{ required: true }]}>
                                        <Select
                                            placeholder="Pilih Ceklis Insiden"
                                            mode="multiple"
                                            options={[
                                                { value: "tools_value", label: "Alat / Lingkungan" },
                                                { value: "personnel_value", label: "Personil" },
                                                { value: "working_method_value", label: "Metode Kerja" },
                                                { value: "document_value", label: "Dokumen" },
                                                { value: "cost_value", label: "Biaya" },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Form.Item name="incident_description" label="Uraian Insiden" rules={[{ required: true }]}>
                                        <Input.TextArea rows={3} />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item name="cause_problem" label="Penyebab Insiden" rules={[{ required: true }]}>
                                        <Input.TextArea rows={3} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Form.Item name="temporary_corrective_action" label="Koreksi (Sementara)" rules={[{ required: true }]}>
                                        <Input.TextArea rows={3} />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item name="corrective_action" label="Tindakan Perbaikan (Permanen)" rules={[{ required: true }]}>
                                        <Input.TextArea rows={3} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Form.Item name="corrective_verification_description" label="Verifikasi Keefektifan dan keefesienan Tindakan Koreksi" rules={[{ required: true }]}>
                                        <Input.TextArea rows={3} />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item name="corrective_verification_status" label="Status" rules={[{ required: true }]}>
                                        <Select
                                            placeholder="Pilih Status Verifikasi"
                                            options={[
                                                { value: "OPEN", label: "Open" },
                                                { value: "CLOSE", label: "Close" },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Form.Item name="perb_verification_description" label="Verifikasi Keefektifan dan keefisienan Tindakan Perbaikan" rules={[{ required: true }]}>
                                        <Input.TextArea rows={3} />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item name="perb_verification_status" label="Status" rules={[{ required: true }]}>
                                        <Select
                                            placeholder="Pilih Status Verifikasi"
                                            options={[
                                                { value: "OPEN", label: "Open" },
                                                { value: "CLOSE", label: "Close" },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Form.Item name="incident_conclusion_description" label="Kesimpulan" rules={[{ required: true }]}>
                                        <Input.TextArea rows={3} />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item name="incident_conclusion_status" label="Status" rules={[{ required: true }]}>
                                        <Select
                                            placeholder="Pilih Status Kesimpulan"
                                            options={[
                                                { value: "OPEN", label: "Open" },
                                                { value: "CLOSE", label: "Close" },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    }
                </Card>
            </Card>
        </div>
    )
}

export default Index