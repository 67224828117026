import { useEffect, useState } from 'react';
import { Card, Typography, Space, Button, Select, Empty, Tabs, Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { UsergroupAddOutlined, FormOutlined, ZoomInOutlined, BarsOutlined } from '@ant-design/icons'
import { decrypt } from '../../../helpers/crypto'
import { setSessionStorage, getSessionStorage } from '../../../helpers/sessionStorage';
import authData from '../../../helpers/authData'

const { Text } = Typography
const { Option } = Select
const { TabPane } = Tabs;

const Index = () => {
    const [appraisals, setAppraisals] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [divisions, setDivisions] = useState([])
    const [roles, setRoles] = useState([])
    const [kpiAverage, setKpiAverage] = useState(0)

    const initialDivision = () => {
        if (getSessionStorage('appraisalDivision')) {
            return parseInt(getSessionStorage('appraisalDivision'))
        }
        return null
    }

    const fetchAppraisals = (divisionId = 0) => {
        const role = localStorage.getItem('role') ? JSON.parse(decrypt(localStorage.getItem('role'))) : []
        setLoaded(false)
        SentralModel.action('Appraisal', 'getAppraisal', { division_id: divisionId, role: (role.includes('HR') || role.includes('SUPERADMIN')) ? 'HR' : 'USER' }).then(res => {
            if (authData.employee_code === 'ADM001') {
                setAppraisals(res.data.employees)
            } else {
                let totalKpi = 0
                let arr = []
                res.data.employees.forEach((el) => {
                    const emp = el?.appraisal?.evaluators.find((i) => i.evaluator_code === authData.employee_code)
                    if (emp) {
                        arr.push(el)

                        let empKpi = 0
                        el?.appraisal?.evaluators.forEach((i) => {
                            empKpi += i.kpi_value
                        })

                        totalKpi += (empKpi / el?.appraisal?.evaluators?.length)
                    }
                })
                setKpiAverage(totalKpi / arr.length)
                setAppraisals(arr)
            }
            setRoles(role)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchDivisions = () => {
        SentralModel.list('Division', { fields: "division_id,division_name", orderBy: "division_name", order: "ASC" }).then((res) => {
            setDivisions(res.data)
        })
    }

    useEffect(() => {
        let divisionId = sessionStorage.getItem('appraisalDivision') || 0
        if (divisionId) {
            setSelectedDivision(parseInt(divisionId))
        } else {
            setSelectedDivision(0)
        }
        fetchDivisions()
        fetchAppraisals(divisionId)
        // eslint-disable-next-line
    }, []);

    const changeDivision = (v) => {
        setLoaded(false)
        fetchAppraisals(v)
        setSessionStorage('appraisalDivision', v)
        setSelectedDivision(v)
    }

    return (
        <div>
            <Card title={
                <div className="full-width">
                    <Text strong style={{ float: 'left' }}>Appraisal List</Text>
                </div>}>

                <Row hidden>
                    <div className="full-width">
                        <Row className="mb-3" justify='end'>
                            <Col>
                                <Space>
                                    <Select disabled placeholder="Select Division" defaultValue={initialDivision} style={{ width: 250 }} onChange={changeDivision} loading={divisions.length === 0}>
                                        <Option key="division_all" value={0}>Semua Divisi</Option>
                                        {
                                            divisions.map((el, key) => (
                                                <Option key={'division_' + key} value={el.division_id}>{el.division_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                </Row>

                {
                    (loaded) ?
                        <div>
                            <Tabs defaultActiveKey="appraisal" type="card">
                                <TabPane tab={<span>Permanent</span>} key="permanent">
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Employee Name</th>
                                                <th>Division Name</th>
                                                {
                                                    (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                        <th>Evaluator</th>
                                                        : null
                                                }
                                                <th className="text-center">KPI</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                appraisals.filter(el => el.employee_status === 'ESPR').map((el, i) => (
                                                    <tr key={i}>
                                                        <td style={{ verticalAlign: 'top', textAlign: 'center' }}>{i + 1}</td>
                                                        <td style={{ verticalAlign: 'top' }}>{el.name}</td>
                                                        <td style={{ verticalAlign: 'top' }}>{(el.division) ? el.division.division_name : ''}</td>
                                                        {
                                                            (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                <td style={{ padding: '0px' }}>
                                                                    {
                                                                        (el.appraisal) ?
                                                                            <table className="table-noborder" style={{ width: '100%', margin: '0px' }}>
                                                                                <tbody>
                                                                                    {
                                                                                        el.appraisal.evaluators.map((ev, x) => (
                                                                                            <tr key={x}>
                                                                                                <td>{ev.employee.name}</td>
                                                                                                <td className="text-center" style={{ width: '70px' }}>{ev.kpi_value}</td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                            : null
                                                                    }
                                                                </td>
                                                                : null
                                                        }
                                                        <td className="text-center" style={{ verticalAlign: 'top' }}>
                                                            {
                                                                (el.appraisal) ?
                                                                    (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                        el.appraisal.kpi_value?.toFixed(2) : el.appraisal.evaluators[0].kpi_value?.toFixed(2)
                                                                    : ''
                                                            }
                                                        </td>
                                                        <td style={{ width: '15%', verticalAlign: 'top', padding: 0, paddingTop: '5px' }}>
                                                            <Space>
                                                                {
                                                                    (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                        (!el.appraisal) ?
                                                                            null
                                                                            :
                                                                            <div>
                                                                                {
                                                                                    (!el.appraisal.kpi_value) ?
                                                                                        <div>
                                                                                            {
                                                                                                (el.appraisal.evaluators.filter((x => x.evaluator_code === authData.employee_code)).length > 0) ?
                                                                                                    <Link to={'/submission-list/appraisal-form/' + el.appraisal.kpi_appraisal_id}>
                                                                                                        <Button type="link" icon={<FormOutlined />} />
                                                                                                    </Link>
                                                                                                    : null
                                                                                            }
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                        :
                                                                        (el.appraisal.evaluators.filter((x => x.evaluator_code === authData.employee_code)).length > 0 && !el.appraisal.kpi_value) ?
                                                                            <Link to={'/submission-list/appraisal-form/' + el.appraisal.kpi_appraisal_id}>
                                                                                <Button type="link" icon={<FormOutlined />} />
                                                                            </Link>
                                                                            :
                                                                            <span className="text-muted" style={{ paddingLeft: 5 }}>Penilaian sudah dikunci</span>
                                                                }
                                                            </Space>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            {
                                                (appraisals.filter(el => el.employee_status === 'ESPR').length === 0) ?
                                                    <tr><td colSpan={5}><Empty /></td></tr> : null
                                            }
                                            {
                                                (appraisals.filter(el => el.employee_status === 'ESPR').length > 0) ? <tr>
                                                    <td colSpan={3}></td>
                                                    <td style={{ padding: 0 }}>
                                                        <table className="table-noborder" style={{ width: '100%', margin: '0px' }}>
                                                            <tbody>
                                                                <tr>
                                                                    <th style={{ textAlign: 'end', width: '78%' }} colSpan={3}>KPI Average</th>
                                                                    <td style={{ textAlign: 'start', fontWeight: 'bold' }}>{kpiAverage.toFixed(3)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr> : null
                                            }
                                        </tbody>

                                    </table>
                                </TabPane>

                                <TabPane tab={<span>Probation</span>} key="probation">
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Employee Name</th>
                                                <th>Division Name</th>
                                                {
                                                    (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                        <th>Evaluator</th>
                                                        : null
                                                }
                                                <th className="text-center">KPI</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                appraisals.filter(el => el.employee_status === 'ESPB').map((el, i) => (
                                                    <tr key={i}>
                                                        <td style={{ verticalAlign: 'top', textAlign: 'center' }}>{i + 1}</td>
                                                        <td style={{ verticalAlign: 'top' }}>{el.name}</td>
                                                        <td style={{ verticalAlign: 'top' }}>{(el.division) ? el.division.division_name : ''}</td>
                                                        {
                                                            (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                <td style={{ padding: '0px' }}>
                                                                    {
                                                                        (el.appraisal) ?
                                                                            <table className="table-noborder" style={{ width: '100%', margin: '0px' }}>
                                                                                <tbody>
                                                                                    {
                                                                                        el.appraisal.evaluators.map((ev, x) => (
                                                                                            <tr key={x}>
                                                                                                <td>{ev.employee.name}</td>
                                                                                                <td className="text-center" style={{ width: '70px' }}>{ev.kpi_value}</td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                            : null
                                                                    }
                                                                </td>
                                                                : null
                                                        }
                                                        <td className="text-center" style={{ verticalAlign: 'top' }}>
                                                            {
                                                                (el.appraisal) ?
                                                                    (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                        el.appraisal.kpi_value?.toFixed(2) : el.appraisal.evaluators[0].kpi_value?.toFixed(2)
                                                                    : ''
                                                            }
                                                        </td>
                                                        <td style={{ width: '15%', verticalAlign: 'top', padding: 0, paddingTop: '5px' }}>
                                                            <Space>
                                                                {
                                                                    (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                        (!el.appraisal) ?
                                                                            <div>
                                                                                <Link to={'/management/appraisal/set-item/' + el.employee_code}>
                                                                                    <Button type="link" icon={<BarsOutlined />} />
                                                                                </Link>
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                {
                                                                                    (!el.appraisal.kpi_value) ?
                                                                                        <div>
                                                                                            <Link to={'/management/appraisal/set-item/' + el.employee_code}>
                                                                                                <Button type="link" icon={<BarsOutlined />} />
                                                                                            </Link>
                                                                                            <Link to={'/management/appraisal/set-evaluator/' + el.appraisal.kpi_appraisal_id}>
                                                                                                <Button type="link" icon={<UsergroupAddOutlined />} />
                                                                                            </Link>
                                                                                            {
                                                                                                (el.appraisal.evaluators.filter((x => x.evaluator_code === authData.employee_code)).length > 0) ?
                                                                                                    <Link to={'/management/appraisal/form-appraisal/' + el.appraisal.kpi_appraisal_id}>
                                                                                                        <Button type="link" icon={<FormOutlined />} />
                                                                                                    </Link>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (el.appraisal.evaluators.length > 0) ?
                                                                                                    <Link to={'/management/appraisal/detail?division=' + selectedDivision + '&appraisalId=' + el.appraisal.kpi_appraisal_id}>
                                                                                                        <Button type="link" icon={<ZoomInOutlined />} />
                                                                                                    </Link>
                                                                                                    : null
                                                                                            }
                                                                                        </div>
                                                                                        :
                                                                                        (el.appraisal.evaluators.length > 0) ?
                                                                                            <Link to={'/management/appraisal/detail?division=' + selectedDivision + '&appraisalId=' + el.appraisal.kpi_appraisal_id}>
                                                                                                <Button type="link" icon={<ZoomInOutlined />} />
                                                                                            </Link>
                                                                                            : null
                                                                                }
                                                                            </div>
                                                                        :
                                                                        (el.appraisal.evaluators.filter((x => x.evaluator_code === authData.employee_code)).length > 0 && !el.appraisal.kpi_value) ?
                                                                            <Link to={'/management/appraisal/form-appraisal/' + el.appraisal.kpi_appraisal_id}>
                                                                                <Button type="link" icon={<FormOutlined />} />
                                                                            </Link>
                                                                            : <span className="text-muted" style={{ paddingLeft: 5 }}>Penilaian sudah dikunci</span>
                                                                }
                                                            </Space>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            {
                                                (appraisals.filter(el => el.employee_status === 'ESPB').length === 0) ?
                                                    <tr><td colSpan={5}><Empty /></td></tr> : null
                                            }
                                        </tbody>

                                    </table>
                                </TabPane>

                                <TabPane tab={<span>Contract</span>} key="contract">
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Employee Name</th>
                                                <th>Division Name</th>
                                                {
                                                    (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                        <th>Evaluator</th>
                                                        : null
                                                }
                                                <th className="text-center">KPI</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                appraisals.filter(el => el.employee_status === 'ESKN').map((el, i) => (
                                                    <tr key={i}>
                                                        <td style={{ verticalAlign: 'top', textAlign: 'center' }}>{i + 1}</td>
                                                        <td style={{ verticalAlign: 'top' }}>{el.name}</td>
                                                        <td style={{ verticalAlign: 'top' }}>{(el.division) ? el.division.division_name : ''}</td>
                                                        {
                                                            (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                <td style={{ padding: '0px' }}>
                                                                    {
                                                                        (el.appraisal) ?
                                                                            <table className="table-noborder" style={{ width: '100%', margin: '0px' }}>
                                                                                <tbody>
                                                                                    {
                                                                                        el.appraisal.evaluators.map((ev, x) => (
                                                                                            <tr key={x}>
                                                                                                <td>{ev.employee.name}</td>
                                                                                                <td className="text-center" style={{ width: '70px' }}>{ev.kpi_value}</td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                            : null
                                                                    }
                                                                </td>
                                                                : null
                                                        }
                                                        <td className="text-center" style={{ verticalAlign: 'top' }}>
                                                            {
                                                                (el.appraisal) ?
                                                                    (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                        el.appraisal.kpi_value?.toFixed(2) : el.appraisal.evaluators[0].kpi_value?.toFixed(2)
                                                                    : ''
                                                            }
                                                        </td>
                                                        <td style={{ width: '15%', verticalAlign: 'top', padding: 0, paddingTop: '5px' }}>
                                                            <Space>
                                                                {
                                                                    (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                        (!el.appraisal) ?
                                                                            <div>
                                                                                <Link to={'/management/appraisal/set-item/' + el.employee_code}>
                                                                                    <Button type="link" icon={<BarsOutlined />} />
                                                                                </Link>
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                {
                                                                                    (!el.appraisal.kpi_value) ?
                                                                                        <div>
                                                                                            <Link to={'/management/appraisal/set-item/' + el.employee_code}>
                                                                                                <Button type="link" icon={<BarsOutlined />} />
                                                                                            </Link>
                                                                                            <Link to={'/management/appraisal/set-evaluator/' + el.appraisal.kpi_appraisal_id}>
                                                                                                <Button type="link" icon={<UsergroupAddOutlined />} />
                                                                                            </Link>
                                                                                            {
                                                                                                (el.appraisal.evaluators.filter((x => x.evaluator_code === authData.employee_code)).length > 0) ?
                                                                                                    <Link to={'/management/appraisal/form-appraisal/' + el.appraisal.kpi_appraisal_id}>
                                                                                                        <Button type="link" icon={<FormOutlined />} />
                                                                                                    </Link>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                (el.appraisal.evaluators.length > 0) ?
                                                                                                    <Link to={'/management/appraisal/detail?division=' + selectedDivision + '&appraisalId=' + el.appraisal.kpi_appraisal_id}>
                                                                                                        <Button type="link" icon={<ZoomInOutlined />} />
                                                                                                    </Link>
                                                                                                    : null
                                                                                            }
                                                                                        </div>
                                                                                        :
                                                                                        (el.appraisal.evaluators.length > 0) ?
                                                                                            <Link to={'/management/appraisal/detail?division=' + selectedDivision + '&appraisalId=' + el.appraisal.kpi_appraisal_id}>
                                                                                                <Button type="link" icon={<ZoomInOutlined />} />
                                                                                            </Link>
                                                                                            : null
                                                                                }
                                                                            </div>
                                                                        :
                                                                        (el.appraisal.evaluators.filter((x => x.evaluator_code === authData.employee_code)).length > 0 && !el.appraisal.kpi_value) ?
                                                                            <Link to={'/management/appraisal/form-appraisal/' + el.appraisal.kpi_appraisal_id}>
                                                                                <Button type="link" icon={<FormOutlined />} />
                                                                            </Link>
                                                                            : <span className="text-muted" style={{ paddingLeft: 5 }}>Penilaian sudah dikunci</span>
                                                                }
                                                            </Space>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            {
                                                (appraisals.filter(el => el.employee_status === 'ESKN').length === 0) ?
                                                    <tr><td colSpan={5}><Empty /></td></tr> : null
                                            }
                                        </tbody>

                                    </table>
                                </TabPane>
                            </Tabs>
                        </div>
                        :
                        <Spinner />
                }
            </Card>
        </div>
    );
}

export default Index;
