import React, {useState} from 'react';
import {Card, message} from 'antd';
import {PageTitle} from '../../../components'
import RequestForm from './RequestForm'
import {useHistory} from 'react-router-dom'
import {SentralModel} from '../../../models/SentralModel'

const Create = () => {
    const history = useHistory();
    const [saving, setSaving] = useState(false);

    const submitMom = (v) => {
        setSaving(true);
        SentralModel.action('Submission', 'saveMom', v, 0).then(() => {
            message.destroy();
            message.success('MoM Submitted', 1);
            setSaving(false);
            setTimeout(() => {
                history.push('/mom');
            }, 1000);
        }).catch(() => {
            message.destroy();
            message.error('Terjadi kesalahan, silahkan coba lagi', 2);
            setSaving(false);
        })
    }
    
    return (
        <Card className="content-container">
            <PageTitle title="Minutes of Meeting" breadcrumbs={[['Minutes of Meeting', '/mom'], ['Create']]}></PageTitle>
            <RequestForm onSubmit={(v) => submitMom(v)} saving={saving}/>
        </Card>
    );
}

export default Create;
