import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Tabs, Typography, Descriptions, Space} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import { PageTitle, NewButton, DataTable, Spinner, ShowButton} from '../../../components'
import authData from '../../../helpers/authData'
import {SentralModel} from '../../../models/SentralModel'
import {toIdr} from '../../../utils/currencyHandler'
import {toFullDate} from '../../../utils/dateHandler'

const {Text} = Typography;
const {TabPane} = Tabs;

const Index = () => {
    const [moms, setMoms] = useState(null);
    const [momInfo, setMomInfo] = useState(null);
    const [instruction, setInstruction] = useState('');
    const [available, setAvailable] = useState(false);

    const fetchMom = () => {
        let payload = {
            filter: [["employee_code", authData.employee_code]]
        }

        SentralModel.list('SubmissionMinutesOfMeeting', payload).then(res => {
            setMoms(res.data);
        });
    }

    const fetchInstruction = () => {
        SentralModel.list('Instruction', {filter: [['code','SBMM']]}, null).then((res) => {
            setInstruction(res.data.length ? res.data[0].content : '');
        });
    }

    const fetchMomInfo = () => {
        SentralModel.action('Submission', 'getMonthlyMoM', null, null).then((res) => {
            setMomInfo(res.data[0]);
        });
    }

    const checkApprovalSchema = () => {
        SentralModel.action('Submission', 'checkSchema', {category: 'MOM', division_id: authData.division_id}).then((res) => {
            setAvailable(res.message);
        });
    }

    useEffect(() => {
        fetchMom();
        fetchMomInfo();
        fetchInstruction();
        checkApprovalSchema();
    }, []);
    
    const momData = (moms) ? [
        ...moms.map(el => {
            return {
                id: el.sub_minutes_of_meeting_id,
                total_amount: toIdr(el.total_amount),
                status: el.status,
                approver: el.approver,
                created_at: el.created_at
            }
        })
    ] : [];

    const rmbColumn = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },{
            title: 'Request Date',
            render: (row) => (
                <span>{toFullDate(row.created_at)}</span>
            )
        },{
            title: 'Total Amount',
            dataIndex: 'total_amount',
            key: 'total_amount',
        },{
            title: 'Action',
            align:'center',
            render : (row) => (
                <Space>
                    <ShowButton link to={'/mom/' + row.id} />
                </Space>
            )
        }
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Minutes of Meeting" breadcrumbs={[['Minutes of Meeting']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Request MoM</Text>
                        <NewButton disabled={!available} right to="/mom/create" title={(available) ? "Request MoM" : 'Approval not available'}/>
                    </div>
                }>
                    <Row justify="space-between">
                        <Col span={4}>
                            <Descriptions size="middle" column={1} bordered layout="vertical">
                                <Descriptions.Item label={<Text strong>MoM This Month</Text>}>
                                    {
                                        (momInfo) ? momInfo.total_submission: '0'
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label={<Text strong>Total Amount</Text>}>
                                    {
                                        (momInfo) ? toIdr(momInfo.total_amount) : 'Rp. 0'
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={19} className="instruction-box">
                            <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                        </Col>
                    </Row>
                </Card>
                <br/>
                {
                    (moms) ? 
                        <Tabs defaultActiveKey="waiting" type="card">
                            <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                                <DataTable size="small" columns={rmbColumn} dataSource={momData.filter(el => el.status==='WAITING')} bordered/>
                            </TabPane>
                            <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                                <DataTable size="small" columns={rmbColumn} dataSource={momData.filter(el => el.status==='APPROVED')} bordered/>
                            </TabPane>
                            <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                                <DataTable size="small" columns={rmbColumn} dataSource={momData.filter(el => el.status==='REJECTED')} bordered/>
                            </TabPane>
                        </Tabs>
                    : <Card><Spinner/></Card>
                }
            </Card>
        </div>
    );
}

export default Index;
