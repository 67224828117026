import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Typography} from 'antd'
import { PageTitle, Spinner} from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import {toFullDate} from '../../../utils/dateHandler'
import moment from 'moment'

const {Text} = Typography

const Detail = () => {
    const {requestId} = useParams()
    const [training, setTraining] = useState(null)

    const fetchTraining = (id) => {
       SentralModel.get('SubmissionTraining', {}, id).then((res)=>{
           setTraining(res.data)
       })
    }

    useEffect(() => {
        fetchTraining(requestId)
    }, [requestId]);

    const statusUpdated = () =>{
        setTraining(null)
        fetchTraining(requestId)
        // window.location.reload();
        window.history.back();
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Pengajuan Training dan Sertifikasi" breadcrumbs={[['Submission List','/submission-list'],['Detail']]}></PageTitle>
            </div>
            {
                (training) ? 
                <div>
                    <DetailHeader 
                        name={training.employee.name} 
                        date={training.created_at}
                        status={training.status}/>
                    <Card bordered  className="mb-4">
                        <Text strong>MEDICAL DETAIL</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={12}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <th>Name</th><td>{training.employee.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Divisi</th><td>{training.employee.division.division_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Posisi</th><td>{training.employee.position.position_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Judul Training</th><td>{training.training.title}</td>
                                        </tr>
                                        <tr>
                                            <th>Tanggal</th><td>{toFullDate(training.training.training_date)}, <span>{moment(training.training.start_time, 'HH:mm:ss').format('HH:mm')} - {moment(training.training.end_time, 'HH:mm:ss').format('HH:mm')}</span></td>
                                        </tr>
                                        <tr>
                                            <th>Deskripsi</th><td>{training.training.description}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered>
                        <ApprovalLog category="TRAINING" 
                            requestId={training.sub_training_id} 
                            approvers={training.approver} 
                            onSubmitted={() => statusUpdated()}/>
                    </Card>
                </div> : <Spinner/>
            }
            
        </Card>
    );
}

export default Detail;
