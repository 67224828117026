import React, {useEffect, useState} from 'react';
import {Card, Typography, DatePicker, Select, message, Col, Row, Spin} from 'antd'
import {PageTitle, Spinner, SyncButton} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import {LoadingOutlined, CheckCircleTwoTone, CloseCircleTwoTone} from '@ant-design/icons'
import moment from 'moment'

const {Text} = Typography
const {RangePicker} = DatePicker
const {Option} = Select

const Index = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [divisions, setDivisions] = useState([]);
    // const [selectedDivision, setSelectedDivision] = useState('All');
    const [selectedDivision, setSelectedDivision] = useState(['All']);
    const [employees, setEmployees] = useState([]);
    // const [selectedEmployee, setSelectedEmployee] = useState('All');
    const [selectedEmployee, setSelectedEmployee] = useState(['All']);
    const [loaded, setLoaded] = useState(false);
    const [loadedEmployee, setLoadedEmployee] = useState(true);
    const [searchMachine, setSearchMachine] = useState(false);
    const [loadingMachine, setLoadingMachine] = useState(false);
    const [statusMachine, setStatusMachine] = useState(false);
    const [searchAttendance, setSearchAttendance] = useState(false);
    const [loadingAttendance, setLoadingAttendance] = useState(false);
    const [statusAttendance, setStatusAttendance] = useState(false);

    const fetchDivisions = () => {
        SentralModel.list('Division', {orderBy: "division_name", order: "asc"}).then((res) => {
            setDivisions(res.data);
            setLoaded(true);
        });
    }

    const fetchEmployees = (divisionId) => {
        let payload = {
            filter: [
                ["division_id", divisionId],
                ["employee_status", ['ESKN', 'ESMG', 'ESPB', 'ESPR']],
                ["is_active", "=", "1"]
            ],
            fields: "employee_code,attendance_id,name",
            orderBy: "name",
            order: "asc"
        }
        setLoadedEmployee(false);
        SentralModel.list('Employee', payload).then((res) => {
            setEmployees(res.data);
            setLoadedEmployee(true);
        });
    }

    useEffect(() => {
        fetchDivisions();
    }, []);
    
    const changeDate = (v) => {
        setSearchMachine(false);
        setStartDate(moment(v[0]).format('YYYY-MM-DD'));
        setEndDate(moment(v[1]).format('YYYY-MM-DD'));
    }

    const changeDivision = (v) => {
        setSearchAttendance(false);
        setSelectedDivision(v);
        setSelectedEmployee(['All']);
        fetchEmployees(v);
    }

    const changeEmployee = (v) => {
        setSearchAttendance(false);
        setSelectedEmployee(v);
    }

    const syncMachine = () => {
        if (!startDate) {
            message.error('Please select date !', 2);
        } else {
            let payload = {
                start_date: startDate,
                end_date: endDate
            }

            setSearchMachine(true);
            setLoadingMachine(true);
            SentralModel.action('Sync','syncMachine', payload, 0).then(() => {
                setLoadingMachine(false);
                setStatusMachine(true);
                message.destroy();
                message.success('Completed', 1);
            }).catch(() => {
                setLoadingMachine(false);
                setStatusMachine(false);
                message.destroy();
                message.error('Oops, something went wrong', 2);
            })
        }
    }

    const syncAttendance = () => {
        let payload = {
            division_id: selectedDivision,
            employee_code: selectedEmployee
        }

        setSearchAttendance(true);
        setLoadingAttendance(true);
        SentralModel.action('Sync','syncAttendance', payload, 0).then(() => {
            setLoadingAttendance(false);
            setStatusAttendance(true);
            message.destroy();
            message.success('Completed', 1);
        }).catch(() => {
            setLoadingAttendance(false);
            setStatusAttendance(false);
            message.destroy();
            message.error('Oops, something went wrong', 2);
        })
    }

    const syncPayroll = () => {
        let payload = {
            division_id: selectedDivision,
            employee_code: selectedEmployee
        }

        setSearchAttendance(true);
        setLoadingAttendance(true);
        SentralModel.action('Sync','syncPayroll', payload, 0).then(() => {
            setLoadingAttendance(false);
            setStatusAttendance(true);
            message.destroy();
            message.success('Completed', 1);
        }).catch(() => {
            setLoadingAttendance(false);
            setStatusAttendance(false);
            message.destroy();
            message.error('Oops, something went wrong', 2);
        })
    }

    const disabledDate = (current) => {
        return current > moment().startOf('day').add(1, 'days');
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Sync" breadcrumbs={[['Sync']]}></PageTitle>
                {
                    loaded ?
                    <>
                        <Card title={<div className="full-width"><Text strong style={{float:'left'}}>Sync Machine</Text></div> }>
                            <Col>
                                <Row justify='space-between'>
                                    <RangePicker style={{width:'80%'}} format="DD MMMM YYYY" disabledDate={disabledDate} onChange={(v) => changeDate(v)} />
                                    <SyncButton title='Sync Machine' onClick={syncMachine} loading={loadingMachine}/>
                                </Row>
                            </Col>
                            {
                                searchMachine ?
                                    <Col>
                                        <Row justify='center'>
                                            <div className="full-width text-center" style={{marginTop: '2%'}}>
                                                {
                                                    loadingMachine ?
                                                        <>
                                                            <Spin indicator={<LoadingOutlined style={{ fontSize: '60px' }} spin />} /><br /><br />
                                                            Syncing...
                                                        </>
                                                        : statusMachine ?
                                                            <>
                                                                <CheckCircleTwoTone style={{fontSize:'60px'}} twoToneColor="#52c41a" /><br/><br/>
                                                                Sync Completed !
                                                            </> 
                                                            :
                                                                <>
                                                                    <CloseCircleTwoTone style={{fontSize:'60px'}} twoToneColor="#ff4d4e" /><br/><br/>
                                                                    Sync Failed !
                                                                </>
                                                }
                                            </div>
                                        </Row>
                                    </Col>
                                : null
                            }
                        </Card>

                        <br />
                        
                        <Card title={<div className="full-width"><Text strong style={{float:'left'}}>Sync Attendance & Payroll</Text></div> }>
                            <Col>
                                <Row justify='space-between'>
                                    <Select
                                        style={{ width: '30%' }}
                                        defaultValue="All"
                                        mode="multiple"
                                        onChange={(v) => { changeDivision(v) }} 
                                        allowClear>
                                        <Option key='all' value='All'>All Division</Option>
                                        {
                                            divisions.map((el, key) => (
                                                <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                            ))
                                        }
                                    </Select>
                                    {
                                        loadedEmployee ?
                                            <Select
                                                style={{ width: '35%' }}
                                                defaultValue="All"
                                                mode="multiple"
                                                onChange={(v) => { changeEmployee(v) }} 
                                                allowClear>
                                                <Option key='all' value='All'>All Employee</Option>
                                                {
                                                    employees.map((el, key) => (
                                                        <Option key={key} value={el.employee_code}>{el.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        : <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}/>
                                    }
                                    <Row>
                                        <SyncButton title='Sync Attendance' onClick={syncAttendance} loading={loadingAttendance}/>&nbsp;&nbsp;&nbsp;
                                        <SyncButton title='Sync Payroll' onClick={syncPayroll} loading={loadingAttendance}/>
                                    </Row>
                                </Row>
                            </Col>
                            {
                                searchAttendance ?
                                    <Col>
                                        <Row justify='center'>
                                            <div className="full-width text-center" style={{marginTop: '2%'}}>
                                                {
                                                    loadingAttendance ?
                                                        <>
                                                            <Spin indicator={<LoadingOutlined style={{ fontSize: '60px' }} spin />} /><br /><br />
                                                            Syncing...
                                                        </>
                                                        : statusAttendance ?
                                                            <>
                                                                <CheckCircleTwoTone style={{fontSize:'60px'}} twoToneColor="#52c41a" /><br/><br/>
                                                                Sync Completed !
                                                            </> 
                                                            :
                                                                <>
                                                                    <CloseCircleTwoTone style={{fontSize:'60px'}} twoToneColor="#ff4d4e" /><br/><br/>
                                                                    Sync Failed !
                                                                </>
                                                }
                                            </div>
                                        </Row>
                                    </Col>
                                : null
                            }
                        </Card>
                    </>
                    : <Spinner />
                }
            </Card>
        </div>
    );
}

export default Index;
