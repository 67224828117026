import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Typography} from 'antd'
import { PageTitle, Spinner} from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import {toFullDate} from '../../../utils/dateHandler'

const {Text} = Typography

const Detail = () => {
    const {requestId} = useParams()
    const [recruitment, setRecruitment] = useState(null)

    const fetchRecruitment = (id) => {
       SentralModel.get('SubmissionRecruitment', {}, id).then((res)=>{
           setRecruitment(res.data)
       })
    }

    useEffect(() => {
        fetchRecruitment(requestId)
    }, [requestId]);

    const statusUpdated = () =>{
        setRecruitment(null)
        fetchRecruitment(requestId)
        // window.location.reload();
        window.history.back();
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Recruitment Request" breadcrumbs={[['Submission List','/submission-list'],['Detail']]}></PageTitle>
            </div>
            {
                (recruitment) ? 
                <div>
                    <DetailHeader 
                        name={recruitment.employee.name} 
                        date={recruitment.created_at}
                        status={recruitment.status}/>
                    <Card bordered  className="mb-4">
                        <Text strong>RECRUITMENT DETAIL</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={12}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                <tbody>
                                        <tr>
                                            <th>Requester</th><td>{recruitment.employee.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Subject</th><td>{recruitment.subject}</td>
                                        </tr>
                                        <tr>
                                            <th>Division</th><td>{recruitment.division.division_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Position</th><td>{recruitment.position.position_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Recruitment Date</th> 
                                            <td>
                                                {toFullDate(recruitment.from_date)} - {toFullDate(recruitment.to_date)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Work Experience</th><td>{recruitment.min_work_experience} {recruitment.min_work_experience > 1 ? 'years' : 'year'}</td>
                                        </tr>
                                        <tr>
                                            <th>Education</th><td>{recruitment.education.description} {recruitment.min_education}</td>
                                        </tr>
                                        <tr>
                                            <th>Age Limit</th><td>{recruitment.age_limit} years</td>
                                        </tr>
                                        <tr>
                                            <th>Quota</th><td>{recruitment.quota}</td>
                                        </tr>
                                        <tr>
                                            <th>Work Location</th><td>{recruitment.location.province_name}</td>
                                        </tr>
                                        <tr>
                                            <th style={{verticalAlign: 'top'}}>Requirement</th>
                                            <td style={{whiteSpace: 'pre-line', textAlign: 'justify'}}>{recruitment.requirement}</td>
                                        </tr>
                                        <tr>
                                            <th style={{verticalAlign: 'top'}}>Job Description</th>
                                            <td style={{whiteSpace: 'pre-line', textAlign: 'justify'}}>{recruitment.job_description}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered>
                        <ApprovalLog category="RECRUITMENT" 
                            requestId={recruitment.sub_recruitment_id} 
                            approvers={recruitment.approver} 
                            onSubmitted={() => statusUpdated()}/>
                    </Card>
                </div> : <Spinner/>
            }
            
        </Card>
    );
}

export default Detail;
