import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Tabs, Badge } from 'antd'
import {
    EnvironmentOutlined, CoffeeOutlined, ReconciliationOutlined, PlusSquareOutlined, FieldTimeOutlined,
    ScissorOutlined, WalletOutlined, MoneyCollectOutlined, AimOutlined, SolutionOutlined, QrcodeOutlined, DollarOutlined,
    ClockCircleOutlined, CheckOutlined, CheckCircleOutlined, UserSwitchOutlined, UnorderedListOutlined
} from '@ant-design/icons'
import { setSessionStorage, getSessionStorage } from '../../helpers/sessionStorage'
import { PageTitle, Spinner } from '../../components'
import BusinessTrip from './businessTrip/Index'
import CashAdvance from './cashAdvance/Index'
import Entertainment from './entertainment/Index'
import EntertainmentRealization from './entertainmentRealization/Index'
import Leave from './leave/Index'
import LeaveRealization from './leaveRealization/Index'
import Medical from './medical/Index'
import MonthlyReport from './monthlyReport/Index'
import Overtime from './overtime/Index'
import OvertimeRealization from './overtimeRealization/Index'
import OfficeStationery from './officeStationery/Index'
import PurchaseRequest from './purchaseRequest/Index'
import Reimbursement from './reimbursement/Index'
import Training from './training/Index'
import Attendance from './attendance/Index'
import Crf from './crf/Index'
import Resign from './resign/Index'
import Recruitment from './recruitment/Index'
import Voucher from './voucher/Index'
import Comission from './commission/Index'
import authData from '../../helpers/authData'
import { SentralModel } from '../../models/SentralModel';
import MyTask from '../dashboard/tabContents/Tabs/MyTask';
import AssignTask from '../dashboard/tabContents/Tabs/AssignedTask';
import Appraisal from './appraisal/Index'
import { useSelector } from 'react-redux';
// import Recruitment from './recruitment/Index'

const { TabPane } = Tabs;

const Index = () => {
    // if (authData.approval === null) window.location.href = '/notfound404'

    const dataAssigns = useSelector((state) => state.myTask.dataAssigns)
    const [leaves, setLeaves] = useState(null)
    const [leaveRealizations, setLeaveRealizations] = useState(null)
    const [overtimes, setOvertimes] = useState(null)
    const [overtimeRealizations, setOvertimeRealizations] = useState(null)
    const [medicals, setMedicals] = useState(null)
    const [reimbursements, setReimbursements] = useState(null)
    const [purchaseRequests, setPurchaseRequests] = useState(null)
    const [entertainments, setEntertainments] = useState(null)
    const [entertainmentRealizations, setEntertainmentRealizations] = useState(null)
    const [businessTrips, setBusinessTrips] = useState(null)
    const [stationeries, setStationeries] = useState(null)
    const [trainings, setTrainings] = useState(null)
    const [attendances, setAttendances] = useState(null)
    const [crfs, setCrfs] = useState(null)
    const [recruitments, setRecruitments] = useState(null)
    const [resigns, setResigns] = useState(null)
    const [vouchers, setVouchers] = useState(null)
    const [commissions, setComissions] = useState(null)
    const [cashAdvances, setCashAdvances] = useState(null)
    const [monthlyReports, setMonthlyReports] = useState(null)
    const [notifications, setNotifications] = useState([])
    const defaultSelectedTab = getSessionStorage('selectedSubmissionTab')
    const defaultSelectedPane = getSessionStorage('task')

    const fetchSubmission = (category) => {
        SentralModel.action('Submission', 'getSubmissionList', { category: category }, 0).then((res) => {
            if (category === 'LEAVE') {
                setLeaves(res.data)
            } else if (category === 'LEAVEREALIZATION') {
                setLeaveRealizations(res.data)
            } else if (category === "OVERTIME") {
                setOvertimes(res.data)
            } else if (category === "OVERTIMEREALIZATION") {
                setOvertimeRealizations(res.data)
            } else if (category === "MEDICAL") {
                setMedicals(res.data)
            } else if (category === "REIMBURSEMENT") {
                setReimbursements(res.data)
            } else if (category === "PURCHASEREQUEST") {
                setPurchaseRequests(res.data)
            } else if (category === "ENTERTAINMENT") {
                setEntertainments(res.data)
            } else if (category === "ENTERTAINMENTREALIZATION") {
                setEntertainmentRealizations(res.data)
            } else if (category === "BUSINESSTRIP") {
                setBusinessTrips(res.data)
            } else if (category === "STATIONERY") {
                setStationeries(res.data)
            } else if (category === "TRAINING") {
                setTrainings(res.data)
            } else if (category === "ATTENDANCE") {
                setAttendances(res.data)
            } else if (category === "CRF") {
                setCrfs(res.data)
            } else if (category === 'RESIGN') {
                setResigns(res.data)
            } else if (category === 'RECRUITMENT') {
                setRecruitments(res.data)
            } else if (category === 'VOUCHER') {
                setVouchers(res.data)
            } else if (category === 'COMMISSION') {
                setComissions(res.data)
            } else if (category === 'CASHADVANCE') {
                setCashAdvances(res.data)
            } else if (category === 'MONTHLYREPORT') {
                setMonthlyReports(res.data)
            }
        })
    }

    const fetchNotification = () => {
        SentralModel.action('Submission', 'getNotification', {}, 0).then((res) => {
            setNotifications(res.data)
        })
    }

    useEffect(() => {
        fetchNotification()
        if (defaultSelectedTab) {
            fetchSubmission(defaultSelectedTab.toUpperCase())
        } else {
            fetchSubmission('LEAVE')
        }
    }, [defaultSelectedTab]);

    const changeTab = (v) => {
        setSessionStorage('selectedSubmissionTab', v)
        if (v === 'leave' && !leaves) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'leaveRealization' && !leaveRealizations) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'overtime' && !overtimes) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'overtimeRealization' && !overtimeRealizations) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'medical' && !medicals) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'reimbursement' && !reimbursements) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'purchaseRequest' && !purchaseRequests) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'entertainment' && !entertainments) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'entertainmentRealization' && !entertainmentRealizations) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'businessTrip' && !businessTrips) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'stationery' && !stationeries) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'training' && !trainings) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'attendance' && !attendances) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'crf' && !crfs) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'recruitment' && !recruitments) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'resign' && !resigns) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'voucher' && !vouchers) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'commission' && !commissions) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'cashAdvance' && !cashAdvances) {
            fetchSubmission(v.toUpperCase())
        }
        if (v === 'monthlyReport' && !monthlyReports) {
            fetchSubmission(v.toUpperCase())
        }
    }

    const changePane = (v) => {
        setSessionStorage('task', v)
        if (v === 'task') {
            setSessionStorage('task')
        }
        if (v === 'approval') {
            setSessionStorage('approval')
        }
    }

    return (
        <Card className="content-container">
            <PageTitle title="Task & Approval" breadcrumbs={[['Task & Approval']]}></PageTitle>
            <Tabs defaultActiveKey={defaultSelectedPane} type='card' onChange={changePane} onTabClick={changePane}>
                <TabPane tab={
                        <span><ClockCircleOutlined style={{ color: '#4ca3ff' }} />
                            Task
                        </span>
                    } key="task">
                    <Row>
                        <Col span={24}>
                            <Tabs type="card" tabPosition="left" defaultActiveKey="my_task">
                                <TabPane tab={<span><CheckCircleOutlined/> My Task</span>} key="my_task">
                                    <MyTask />
                                </TabPane>
                                <TabPane tab={<span><UserSwitchOutlined/> Assign Task</span>} key="assign_task">
                                    <Tabs defaultActiveKey='status' type='card'>
                                        <TabPane tab={
                                            <span><ClockCircleOutlined style={{ color: '#4ca3ff' }} />
                                                In Progress
                                            </span>
                                        } key="progress-task">
                                            <AssignTask datas={dataAssigns?.progress_task} />
                                        </TabPane>

                                        <TabPane tab={<span><CheckOutlined style={{ color: 'green' }} /> Finished</span>} key="finished-task">
                                            <AssignTask datas={dataAssigns?.finish_task} />
                                        </TabPane>
                                    </Tabs>
                                </TabPane>
                                <TabPane tab={<span><UnorderedListOutlined/> Appraisal</span>} appraisal="approval">
                                    <Appraisal />
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </TabPane>
                {
                    authData.approval === null ? null :
                        <TabPane tab={
                            <span><CheckCircleOutlined style={{ color: 'green' }} />
                                Approval
                            </span>
                        } key="approval">
                            <Row>
                                <Col span={24}>
                                    <Tabs defaultActiveKey={defaultSelectedTab} type="card" tabPosition="left" onChange={changeTab} onTabClick={changeTab} /* className="submission-tab" */>

                                        {authData.approval.leave || authData.approval.leave_cancellation ?
                                            <TabPane tab={
                                                (notifications.includes("LEAVE") || notifications.includes("LEAVECANCELLATION")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <ReconciliationOutlined /> Leave
                                                    </Badge> :
                                                    <span><ReconciliationOutlined /> Leave</span>
                                            } key="leave">
                                                {
                                                    (leaves) ?
                                                        <Leave data={leaves} requestNotif={notifications.includes("LEAVE")} cancellationNotif={notifications.includes("LEAVECANCELLATION")} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.leave_realization ?
                                            <TabPane tab={
                                                (notifications.includes("LEAVEREALIZATION")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <FieldTimeOutlined /> Leave Realization
                                                    </Badge> :
                                                    <span><FieldTimeOutlined /> Leave Realization </span>
                                            } key="leaveRealization">
                                                {
                                                    (leaveRealizations) ?
                                                        <LeaveRealization data={leaveRealizations} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.overtime ?
                                            <TabPane tab={
                                                (notifications.includes("OVERTIME")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <FieldTimeOutlined /> Overtime
                                                    </Badge> :
                                                    <span><FieldTimeOutlined /> Overtime</span>
                                            } key="overtime">
                                                {
                                                    (overtimes) ?
                                                        <Overtime data={overtimes} requestNotif={notifications.includes("OVERTIME")} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.overtime_realization ?
                                            <TabPane tab={
                                                (notifications.includes("OVERTIMEREALIZATION")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <FieldTimeOutlined /> Overtime Realization
                                                    </Badge> :
                                                    <span><FieldTimeOutlined /> Overtime Realization </span>
                                            } key="overtimeRealization">
                                                {
                                                    (overtimeRealizations) ?
                                                        <OvertimeRealization realizations={overtimeRealizations} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.reimbursement ?
                                            <TabPane tab={
                                                (notifications.includes("REIMBURSEMENT")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <MoneyCollectOutlined /> Reimbursement
                                                    </Badge> :
                                                    <span><MoneyCollectOutlined /> Reimbursement</span>
                                            } key="reimbursement">
                                                {
                                                    (reimbursements) ?
                                                        <Reimbursement data={reimbursements} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.commission ?
                                            <TabPane tab={
                                                (notifications.includes("COMISSION")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <DollarOutlined /> Comission
                                                    </Badge> :
                                                    <span><DollarOutlined /> Comission</span>
                                            } key="commission">
                                                {
                                                    (commissions) ?
                                                        <Comission data={commissions} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.entertainment ?
                                            <TabPane tab={
                                                (notifications.includes("ENTERTAINMENT")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <CoffeeOutlined /> Entertainment
                                                    </Badge> :
                                                    <span><CoffeeOutlined /> Entertainment</span>

                                            } key="entertainment">
                                                {
                                                    (entertainments) ?
                                                        <Entertainment data={entertainments} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.entertainment_realization ?
                                            <TabPane tab={
                                                (notifications.includes("ENTERTAINMENTREALIZATION")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <CoffeeOutlined /> Entertainment Realization
                                                    </Badge> :
                                                    <span><CoffeeOutlined /> Entertainment Realization</span>

                                            } key="entertainmentRealization">
                                                {
                                                    (entertainmentRealizations) ?
                                                        <EntertainmentRealization data={entertainmentRealizations} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.medical ?
                                            <TabPane tab={
                                                (notifications.includes("MEDICAL")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <PlusSquareOutlined /> Medical
                                                    </Badge> :
                                                    <span><PlusSquareOutlined /> Medical</span>
                                            } key="medical">
                                                {
                                                    (medicals) ?
                                                        <Medical data={medicals} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.stationery ?
                                            <TabPane tab={
                                                (notifications.includes("STATIONERY")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <ScissorOutlined /> Office Stationery
                                                    </Badge> :
                                                    <span><ScissorOutlined /> Office Stationery</span>

                                            } key="stationery">
                                                {
                                                    (stationeries) ?
                                                        <OfficeStationery data={stationeries} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.business_trip ?
                                            <TabPane tab={
                                                (notifications.includes("BUSINESSTRIP") || notifications.includes("BUSINESSTRIPREALIZATION")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <EnvironmentOutlined /> Business Trip
                                                    </Badge> : <span><EnvironmentOutlined /> Business Trip</span>
                                            }
                                                key="businessTrip">
                                                {
                                                    (businessTrips) ?
                                                        <BusinessTrip data={businessTrips} realizationNotif={notifications.includes("BUSINESSTRIPREALIZATION")} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.cash_advance ?
                                            <TabPane tab={
                                                (notifications.includes("CASHADVANCE") || notifications.includes("CASHADVANCEREALIZATION")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <EnvironmentOutlined /> Cash Advance
                                                    </Badge> : <span><EnvironmentOutlined /> Cash Advance</span>
                                            }
                                                key="cashAdvance">
                                                {
                                                    (cashAdvances) ?
                                                        <CashAdvance data={cashAdvances} realizationNotif={notifications.includes("CASHADVANCEREALIZATION")} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.purchase_on_budget || authData.approval.purchase_over_budget ?
                                            <TabPane tab={
                                                (notifications.includes("PURCHASEONBUDGET") || notifications.includes("PURCHASEOVERBUDGET")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <WalletOutlined /> Purchase Request
                                                    </Badge> :
                                                    <span><WalletOutlined /> Purchase Request</span>
                                            } key="purchaseRequest">
                                                {
                                                    (purchaseRequests) ?
                                                        <PurchaseRequest data={purchaseRequests} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.voucher ?
                                            <TabPane tab={
                                                (notifications.includes("VOUCHER")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <QrcodeOutlined /> Voucher
                                                    </Badge> :
                                                    <span><QrcodeOutlined /> Voucher</span>
                                            } key="voucher">
                                                {
                                                    (vouchers) ?
                                                        <Voucher data={vouchers} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.training ?
                                            <TabPane tab={
                                                (notifications.includes("TRAINING")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <EnvironmentOutlined /> Training & Certification
                                                    </Badge> : <span><EnvironmentOutlined /> Training & Certification</span>
                                            }
                                                key="training">
                                                {
                                                    (trainings) ?
                                                        <Training data={trainings} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.monthly_report ?
                                            <TabPane tab={
                                                (notifications.includes("MONTHLYREPORT")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <PlusSquareOutlined /> Monthly Report
                                                    </Badge> :
                                                    <span><PlusSquareOutlined /> Monthly Report</span>
                                            } key="monthlyReport">
                                                {
                                                    (monthlyReports) ?
                                                        <MonthlyReport data={monthlyReports} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.attendance ?
                                            <TabPane tab={
                                                (notifications.includes("ATTENDANCE")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <EnvironmentOutlined /> Attendance
                                                    </Badge> : <span><AimOutlined /> Attendance</span>
                                            }
                                                key="attendance">
                                                {
                                                    (attendances) ?
                                                        <Attendance data={attendances} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.crf ?
                                            <TabPane tab={
                                                (notifications.includes("CRF")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <ReconciliationOutlined /> CRF
                                                    </Badge> : <span><ReconciliationOutlined /> CRF</span>
                                            }
                                                key="crf">
                                                {
                                                    (crfs) ?
                                                        <Crf data={crfs} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.recruitment ?
                                            <TabPane tab={
                                                (notifications.includes("RECRUITMENT")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <SolutionOutlined /> Recruitment
                                                    </Badge> :
                                                    <span><SolutionOutlined /> Recruitment</span>
                                            } key="recruitment">
                                                {
                                                    (recruitments) ?
                                                        <Recruitment data={recruitments} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }

                                        {authData.approval.resign ?
                                            <TabPane tab={
                                                (notifications.includes("RESIGN")) ?
                                                    <Badge dot className="blinking-badge">
                                                        <ReconciliationOutlined /> Resign
                                                    </Badge> : <span><ReconciliationOutlined /> Resign</span>
                                            }
                                                key="resign">
                                                {
                                                    (resigns) ?
                                                        <Resign data={resigns} /> : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                        }
                                    </Tabs>
                                </Col>
                            </Row>
                        </TabPane>
                }
            </Tabs>
        </Card>
    );
}

export default Index;
