import React from 'react';
import {Badge, Card, Tabs} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import {DataTable, ShowButton} from '../../../../components'
import {toFullDate} from '../../../../utils/dateHandler'
import Status from '../../Status'

const {TabPane} = Tabs;

const Index = (props) => {
    const leaveColumn = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
            title: 'Employee',
            dataIndex: 'name',
            key: 'name'
        },{
            title: 'Date',
            render : (row) => (
                <div>
                    {
                        (row.total_days===1) ? 
                        <span>{toFullDate(row.from_date)}</span>
                        : <span>{toFullDate(row.from_date)} - {toFullDate(row.to_date)}</span>
                    }
                    {
                        (row.cancellation_status==='APPROVED') ? <span style={{color:'red'}}> <br/>cancelled</span> : ''
                    }
                </div>
            )
        },{
            title: 'Necessity',
            dataIndex: 'reason',
            key: 'reason',
        },{
            title:'Action',
            width:'10%',
            render:(row) => (
                <div className="text-center">
                    <ShowButton link to={'/submission-list/leave/'+row.id} />
                </div>
            )
        }
    ];
    const cancellationColumn = [{
            title: 'No',
            dataIndex: 'no',
            key: 'name',
        },
        {
            title: 'Employee',
            dataIndex: 'name',
            key: 'name'
        },{
            title: 'Date',
            render : (row) => (
                (row.total_days===1) ? 
                <span>{toFullDate(row.from_date)}</span>
                : <span>{toFullDate(row.from_date)} - {toFullDate(row.to_date)}</span>
            )
        },{
            title: 'Cancellation Reason',
            dataIndex: 'reason',
            key: 'reason',
        },{
            title: 'Status',
            align:'center',
            render : (row) => (
                 <Status status={row.status} blink={row.status==='WAITING'} />
            )
            // dataIndex: 'cancellation_status',
            // key: 'cancellation_status',
        },{
            title:'Action',
            width:'10%',
            render:(row) => (
                <div className="text-center">
                    <ShowButton link to={'/submission-list/leave-cancellation/'+row.id} />
                </div>
            )
        }
    ];
    const leaveData = [
        ...props.data.map((el) => {
            return{
                id:el.request_id,
                name:el.name,
                leave_type: el.description,
                from_date: el.from_date,
                to_date: el.to_date,
                total_days: el.total_days,
                reason: el.reason,
                division_name: el.division_name,
                status: el.status,
                cancellation_status: el.cancellation_status,
                submitted_at: el.submitted_at
            }
        })
    ]
    const cancellationData = [
        ...props.cancellations.map((el) => {
            return{
                id:el.request_id,
                name:el.name,
                leave_type: el.description,
                from_date: el.from_date,
                to_date: el.to_date,
                total_days: el.total_days,
                reason: el.cancellation_reason,
                division_name: el.division_name,
                status: el.status,
                submitted_at: el.submitted_at
            }
        })
    ]
    return (
        <Card>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={leaveColumn} dataSource={leaveData.filter(el => el.status==='WAITING')} bordered/>
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                    <DataTable size="small" columns={leaveColumn} dataSource={leaveData.filter(el => el.status==='APPROVED')} bordered/>
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={leaveColumn} dataSource={leaveData.filter(el => el.status==='REJECTED')} bordered/>
                </TabPane>
                <TabPane tab={
                    <span>
                        <FieldTimeOutlined style={{color:'red'}}/> 
                        Cancellation {
                            (props.cancellationNotif) ? 
                            <Badge dot className="blinking-badge"></Badge>
                            : null
                        }
                    </span>
                    } key="cancellation">
                    <DataTable size="small" columns={cancellationColumn} dataSource={cancellationData} bordered/>
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Index;
