import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addTicket, changeDivision, changeModalTicket, fetchDivision, fetchEmployee, fetchEmployeePresales, fetchListTicket, fetchTicketAssign, showEditTicket, showInfoTicket, takeTicketPresales } from '../../../../redux/slices/Dashboard/karajoTicketSlice'
import { Button, Col, Divider, Modal, Row, Select, Space, Spin, Tabs } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import FormatTicketSoftware from '../KarajoTicketFormat/FormatTicketSoftware'
import FormatTicketPresales from '../KarajoTicketFormat/FormatTicketPresales'
import FormatTicketGeneral from '../KarajoTicketFormat/FormatTicketGeneral'
import { DataTable, EditButton, ShowButton, TakeButton } from '../../../../components'
import moment from 'moment'
import InfoModalPresales from './Modal/KarajoTicket/Presales/InfoModal'
import InfoModalSoftware from './Modal/KarajoTicket/Software/InfoModal'
import InfoModalGeneral from './Modal/KarajoTicket/General/InfoModal'
import EditModalPresales from './Modal/KarajoTicket/Presales/EditModal'
import EditModalSoftware from './Modal/KarajoTicket/Software/EditModal'
import EditModalGeneral from './Modal/KarajoTicket/General/EditModal'

const { TabPane } = Tabs

const KarajoTicket = () => {
    const dispatch = useDispatch()
    const loadedList = useSelector((state) => state.karajoTicket.loadedList)
    const loadedAssign = useSelector((state) => state.karajoTicket.loadedAssign)
    const listTicket = useSelector((state) => state.karajoTicket.listTicket)
    const assignTicket = useSelector((state) => state.karajoTicket.assignTicket)
    const showModalAddTicket = useSelector((state) => state.karajoTicket.showModalAddTicket)
    const divisionId = useSelector((state) => state.karajoTicket.divisionId)
    const division = useSelector((state) => state.karajoTicket.division)
    const showModalInfoTicket = useSelector((state) => state.karajoTicket.showModalInfoTicket)
    const showModalEditTicket = useSelector((state) => state.karajoTicket.showModalEditTicket)
    const isTicket = useSelector((state) => state.karajoTicket.isTicket)

    useEffect(() => {
        dispatch(fetchListTicket())
        dispatch(fetchTicketAssign())
        dispatch(fetchEmployee())
        dispatch(fetchEmployeePresales())
        dispatch(fetchDivision())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const takeTicket = (v) => {
        dispatch(takeTicketPresales({ all_app_task_id: v })).then(() => {
            dispatch(fetchListTicket())
            dispatch(fetchTicketAssign())
        })
    }

    const columnsList = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
        },
        {
            title: 'Task Origin',
            dataIndex: 'data_from',
            key: 'data_from',
        },
        {
            title: 'Description',
            render: (row) => (
                row.description !== null ?
                    row.description.length > 150 ? `${row?.description.substring(0, 150)}...` : row?.description
                    : "-"
            ),
            key: 'description',
        },
        {
            title: 'Employee',
            render: (row) => (
                row?.multiple_task_to[0]?.employee_code === null ? "-" : row?.multiple_task_to[0]?.task_to?.name
            ),
            key: 'employee'
        },
        {
            title: 'Finished Date',
            render: (row) => (
                row?.finished_date === null || row?.finished_date === "0000-00-00 00:00:00" ? '-' : moment(row?.finished_date).format('DD MMMM YYYY')
            ),
            key: 'finished_date',
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            filters: [
                {
                    text: 'In Progress',
                    value: 'In Progress'
                },
                {
                    text: 'Finished',
                    value: 'Finished'
                }
            ],
            onFilter: (value, record) => record.status === value,
        },
        {
            title: 'Action',
            align: 'center',
            render: (row) => (
                <Space>
                    <ShowButton onShow={() => dispatch(showInfoTicket(row))} />
                </Space>
            ),
            key: 'action'
        }
    ];

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
        },
        {
            title: 'Task Origin',
            dataIndex: 'data_from',
            key: 'data_from',
        },
        {
            title: 'Description',
            render: (row) => (
                row.description !== null ?
                    row.description.length > 150 ? `${row?.description.substring(0, 150)}...` : row?.description
                    : "-"
            ),
            key: 'description',
        },
        {
            title: 'Employee',
            render: (row) => (
                row?.multiple_task_to[0]?.employee_code === null ? "-" : row?.multiple_task_to[0]?.task_to?.name
            ),
            key: 'employee'
        },
        {
            title: 'Finished Date',
            render: (row) => (
                row?.finished_date === null || row?.finished_date === "0000-00-00 00:00:00" ? '-' : moment(row?.finished_date).format('DD MMMM YYYY')
            ),
            key: 'finished_date',
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            filters: [
                {
                    text: 'In Progress',
                    value: 'In Progress'
                },
                {
                    text: 'Finished',
                    value: 'Finished'
                }
            ],
            onFilter: (value, record) => record.status === value,
        },
        {
            title: 'Action',
            align: 'center',
            render: (row) => (
                <Space>
                    {
                        (row?.data_from === "TICKET PRESALES" || row?.data_from === "TICKET SOFTWARE") && row?.multiple_task_to[0]?.employee_code === null ?
                            <TakeButton onTake={() => takeTicket(row.id)} />
                            : null
                    }
                    {
                        (row?.data_from === "TICKET PRESALES" || row?.data_from === "TICKET SOFTWARE") && row?.multiple_task_to[0]?.employee_code === null ?
                            null :
                            row?.status === "Finished" ?
                                <EditButton disabled onEdit={() => dispatch(showEditTicket(row))} /> :
                                <EditButton onEdit={() => dispatch(showEditTicket(row))} />
                    }
                    <ShowButton onShow={() => dispatch(showInfoTicket(row))} />
                </Space>
            ),
            key: 'action'
        }
    ];

    return (
        <div>
            <Row style={{ margin: "5px 0 10px 0" }}>
                <Button type="primary" color="#ededed" onClick={() => dispatch(addTicket())} icon={<PlusCircleOutlined />}>
                    Add Ticket
                </Button>
            </Row>

            <Tabs defaultActiveKey='status' type='card'>
                <TabPane tab="Ticket List" key="tiket_list">
                    {
                        loadedList ?
                            <DataTable columns={columnsList} dataSource={listTicket} bordered size="small" />
                            :
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Spin />
                            </div>
                    }
                </TabPane>

                <TabPane tab="Ticket Assign" key="ticket_assign">
                    {
                        loadedAssign ?
                            <DataTable columns={columns} dataSource={assignTicket} bordered size="small" />
                            :
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Spin />
                            </div>
                    }
                </TabPane>
            </Tabs>

            {
                showModalAddTicket ?
                    <Modal visible={showModalAddTicket} onCancel={() => dispatch(changeModalTicket(false))} title="Add TeamWork Ticket" width={750} footer={
                        <>
                            <Button onClick={() => dispatch(changeModalTicket(false))}>
                                Close
                            </Button>
                        </>
                    }>
                        <Row justify='start' style={{ marginBottom: 15 }}>
                            <Col span={11}>
                                <Select
                                    showSearch
                                    placeholder="Select Division"
                                    options={division}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={(v) => dispatch(changeDivision(v))}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                        </Row>
                        <Divider />
                        {
                            divisionId === 24 ?
                                <FormatTicketSoftware />
                                : divisionId === 13 ?
                                    <FormatTicketPresales />
                                    :
                                    <FormatTicketGeneral />
                        }
                    </Modal>
                    : null
            }

            {
                showModalInfoTicket ?
                    isTicket === "TICKET PRESALES" ?
                        <InfoModalPresales />
                        : isTicket === "TICKET SOFTWARE" ?
                            <InfoModalSoftware />
                            : <InfoModalGeneral />
                    : null
            }

            {
                showModalEditTicket ?
                    isTicket === "TICKET PRESALES" ?
                        <EditModalPresales />
                        : isTicket === "TICKET SOFTWARE" ?
                            <EditModalSoftware />
                            : <EditModalGeneral />
                    : null
            }
        </div>
    )
}

export default KarajoTicket