import React, { useEffect, useState } from 'react';
import { Card, Typography, message, Space, Tag, Select, Form, Input, Switch, Button } from 'antd'
import { AddButton, EditButton, ViewButton, DeleteButton, PageTitle, Spinner, DataTable, FormModal } from '../../../../components'
import { SentralModel } from '../../../../models/SentralModel'
import moment from 'moment'
import { LockOutlined } from '@ant-design/icons'

const { Text } = Typography
const { Option } = Select
const Index = () => {
    const [tests, setTests] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [loaded, setLoaded] = useState(false);
    const [divisions, setDivisions] = useState([])
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)
    const [status, setStatus] = useState(false)

    const fetchMultipleChoices = (divisionId) => {
        SentralModel.list('Test', { filter: [['division_id', divisionId], ['category', 'MULTIPLE']] }).then(res => {
            setLoaded(false)
            setTests(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchDivisions = () => {
        SentralModel.list('Division', { fields: "division_id,division_name" }).then((res) => {
            setDivisions(res.data)
        })
    }

    useEffect(() => {
        fetchMultipleChoices()
        fetchDivisions()
    }, []);

    const dataSource = [
        ...tests.map(el => {
            return {
                id: el.test_id,
                division: el.division.division_name,
                division_id: el.division_id,
                test_name: el.test_name,
                due_date: el.due_date,
                is_active: el.is_active,
            }
        })
    ];

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        key: 'name',
        width: '5%',
    },
    {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        width: '15%'
    },
    {
        title: 'Test Name',
        dataIndex: 'test_name',
        key: 'test_name',
    },
    {
        title: 'Due Date',
        dataIndex: 'due_date',
        key: 'due_date',
        render: (text) => (
            <div className="text-center">
                {text === null ? '-' : moment(text).format('DD MMMM YYYY')}
            </div>
        )
    },
    {
        title: 'Status',
        dataIndex: 'is_active',
        key: 'is_active',
        render: (text) => (
            <div className="text-center">
                <Tag color={text === 1 ? "green" : "red"}>{text === 1 ? "Active" : "Inactive"}</Tag>
            </div>
        )
    },
    {
        title: 'Action',
        width: 120,
        render: (row) => (
            <div className="text-center">
                <Space>
                    <ViewButton link to={"/management/test/multiple-choice/" + row.id} />
                    <EditButton onEdit={() => editTest(row)} />
                    <DeleteButton onConfirm={() => deleteTest(row.id)} />
                    <Button type="link" size="small" shape="circle" onClick={() => editTest(row)} icon={<LockOutlined />} />
                </Space>
            </div>
        )
    },
    ];

    const addTest = () => {
        setModalTitle('Add Test')
        setDefaultValues({
            test_id: 0,
            division_id: '',
            test_name: '',
            is_active: true
        })
        setStatus(true)
        setFormModal(true)
    }

    const editTest = (v) => {
        setModalTitle('Edit Test')
        setDefaultValues({
            test_id: v.id,
            division_id: v.division_id,
            test_name: v.test_name,
            is_active: parseInt(v.is_active)
        })
        setStatus(v.is_active)
        setFormModal(true)
    }

    const deleteTest = (id) => {
        message.loading('deleting test')
        SentralModel.deleteById('Test', id).then((res) => {
            message.destroy()
            message.success('test deleted', 2)
            let tmp = tests
            let index = tmp.findIndex(el => el.test_id === id)
            tmp.splice(index, 1);
            setTests([])
            setTests(tmp)
        })
    }

    const saveTest = (v) => {
        setSaving(true)
        let payload = {
            test_id: v.id,
            category: 'MULTIPLE',
            division_id: v.division_id,
            test_name: v.test_name,
            is_active: status ? 1 : 0
        }
        SentralModel.save('Test', payload, v.test_id).then((res) => {
            setFormModal(false)
            fetchMultipleChoices(v.division_id)
            message.success('Test saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const changeDivision = (v) => {
        setLoaded(false)
        fetchMultipleChoices(v)
        setSelectedDivision(v)
        sessionStorage.setItem('selectedDivision', v)
    }
    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Test Multiple Choices" breadcrumbs={[['Test Multiple Choice']]}></PageTitle>
                <Card title={
                    <div>
                        <div className="full-width">
                            <Text strong style={{ float: 'left' }}>Test Multiple Choice List</Text>

                            <Space style={{ float: 'right' }}>
                                <Select placeholder="Select Division" value={selectedDivision} style={{ width: 250 }} onChange={changeDivision} loading={divisions.length === 0}>
                                    <Option key="division_all" value={0}>All Division</Option>
                                    {
                                        divisions.map((el, key) => (
                                            <Option key={'division_' + key} value={el.division_id}>{el.division_name}</Option>
                                        ))
                                    }
                                </Select>
                                <AddButton right onAdd={addTest} title="New Test" />
                            </Space>
                        </div>
                    </div>
                }>

                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={dataSource} columns={columns} />
                            : <Spinner />
                    }
                </Card>
                {
                    (formModal) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveTest(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="test_id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Division" name="division_id" required rules={[{ required: true }]}>
                                <Select placeholder="Select Division">
                                    {
                                        divisions.map((el, key) => (
                                            <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Name" name="test_name" required rules={[{ required: true }]}>
                                <Input placeholder="Name" />
                            </Form.Item>
                            <Form.Item label="Status" name="is_active" rules={[{ required: true }]}>
                                <Switch checkedChildren="Active" unCheckedChildren="Inactive" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.is_active} />
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    );
}

export default Index;
