import React, { useEffect, useState } from 'react';
import { Card, Typography, Input, message, Space, Row, Col, Form, Select, Checkbox, Button, Spin } from 'antd'
import { EditButton, PageTitle, Spinner, FormModal, DataTable } from '../../../components'
import { CheckCircleTwoTone, CloseCircleTwoTone, ReloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { SentralModel } from '../../../models/SentralModel'
import authData from '../../../helpers/authData'

const { Text } = Typography

function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const Index = () => {
    const forceUpdate = useForceUpdate()
    const [leaveRights, setLeaveRights] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedLeaves, setSelectedLeaves] = useState([]);
    const [leaves, setLeaves] = useState([]);
    const [loadedSync, setLoadedSync] = useState(true);

    const fetchLeaveRights = (year) => {
        setLoaded(false)
        SentralModel.action('HumanResource', 'getLeaveRights', { year: year }).then(res => {
            setLeaveRights(res.data)
            setLoaded(true)
        })
    }

    const fetchLeaves = () => {
        SentralModel.list('Leave').then(res => {
            setLoaded(false)
            setLeaves(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchLeaveRights(new Date().getFullYear())
        fetchLeaves()
    }, []);

    const dataSource = [
        ...leaveRights.map(el => {
            return {
                employee_code: el.employee_code,
                employee_name: el.name,
                is_set: el.is_set,
                leave_rights: el.leave_rights,
            }
        })
    ];

    const columns = [
        {
            title: 'Nama Karyawan',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Hak Cuti',
            align: 'center',
            render: (row) => (
                row.is_set ?
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                    :
                    <CloseCircleTwoTone twoToneColor="red" />
            )
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editLeaveRights(row)} />
                    </Space>
                </div>
            )
        },
    ];

    const editLeaveRights = (v) => {
        setModalTitle('Edit Leave Rights')
        setSelectedEmployee(v.employee_code)
        let val = {};
        let p = [];
        v.leave_rights.forEach(el => {
            val['leave_' + el.leave_id] = el.quota
            p.push(el.leave_id)
        });
        setSelectedLeaves(p)
        leaves.forEach((el, i) => {
            if (!val.hasOwnProperty('leave_' + el.leave_id)) {
                val['leave_' + el.leave_id] = el.quota
            }
        })

        setDefaultValues({
            ...val,
            employee_code: v.employee_code,
            employee_name: v.employee_name
        })
        setFormModal(true)
    }

    const saveLeaveRights = (v) => {
        setSaving(true)
        let data = Object.entries(v)
        let payload = {
            year: selectedYear,
            employee_code: selectedEmployee,
            right: data,
            selected_leaves: selectedLeaves
        }
        SentralModel.action('HumanResource', 'saveLeaveRights', payload, v.emp_medical_allowance_id).then((res) => {
            setFormModal(false)
            fetchLeaveRights();
            message.success('Leave rights saved', 2);
            setSaving(false)
        }).catch(() => {
            message.error('Something went wrong', 2);
            setSaving(false)
        })
    }

    const selectPeriod = (v) => {
        setSelectedYear(v)
        fetchLeaveRights(v)
    }

    const selectLeave = (e, v) => {
        let p = selectedLeaves
        if (e.target.checked) {
            p.push(v)
        } else {
            p.splice(p.findIndex((el) => el === v), 1)
        }
        setSelectedLeaves(p)
        forceUpdate()
    }

    const syncLeaveRights = () => {
        setLoadedSync(false)

        SentralModel.action("HumanResource", "renewalLeaveRights", null, 0).then((res) => {
            message.success("Success Sync")
            setLoadedSync(true)
            
            // setTimeout(() => {
            //     window.location.reload();
            // }, 1000)

            console.log(res.data)
        }).catch((err) => {
            setLoadedSync(true)
            message.warning("Failed Sync")
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Employee Leave Rights" breadcrumbs={[['Leave Rights']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Leave Rights List</Text>
                    </div>}>

                    <Row justify="space-between" className="mb-3">
                        <div style={{ float: 'right' }} className="mb-3">
                            {
                                (authData?.division_id ?? '') === 24 ?
                                    <Button disabled={!loadedSync ? true : false} type='primary' onClick={() => syncLeaveRights()} icon={loadedSync ? <ReloadOutlined /> :
                                        <Spin size="small" indicator={
                                            <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} />
                                    }>Sync Leave Rights</Button>
                                    : null
                            }
                        </div>

                        <div style={{ float: 'left' }} className="mb-3">
                            <Select style={{ width: '200px' }} onChange={(v) => selectPeriod(v)} defaultValue={new Date().getFullYear()}>
                                {
                                    [new Date().getFullYear(), new Date().getFullYear() - 1].map((el, key) => (
                                        <Select.Option value={el} key={key}>{el}</Select.Option>
                                    ))
                                }
                            </Select>
                        </div>
                    </Row>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={dataSource} columns={columns} />
                            :
                            <Spinner />
                    }
                </Card>

                {
                    (formModal) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveLeaveRights(v)} width={600} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            {
                                leaves.map((leave, i) => (
                                    <div className="full-width" key={"leave_" + i}>
                                        <Row>
                                            <Col span={16}>
                                                <Form.Item name={'select_' + leave.leave_id} style={{ padding: 0, margin: '0px' }}>
                                                    <Checkbox checked={selectedLeaves.includes(leave.leave_id)} onChange={(v) => selectLeave(v, leave.leave_id)}>
                                                        {leave.description}
                                                    </Checkbox>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={'leave_' + leave.leave_id} style={{ padding: 0, margin: '0px' }}>
                                                    <Input size="small" placeholder="Quota" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <br />
                                    </div>
                                ))
                            }
                        </FormModal>
                        : null
                }
            </Card>
        </div>
    );
}

export default Index;
