import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography } from 'antd'
import { PageTitle, Spinner } from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import { useParams } from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import { toIdr } from '../../../utils/currencyHandler'

const { Text } = Typography

const Detail = () => {
    const { requestId } = useParams()
    const [commission, setCommission] = useState(null)

    const fetchCommission = (id) => {
        SentralModel.get('SubmissionCommission', {}, id).then((res) => {
            setCommission(res.data)
        })
    }

    useEffect(() => {
        fetchCommission(requestId)
    }, [requestId]);

    const statusUpdated = () => {
        setCommission(null)
        fetchCommission(requestId)
        // window.location.reload();
        window.history.back();
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Commission Request" breadcrumbs={[['Submission List', '/submission-list'], ['Detail']]}></PageTitle>
            </div>
            {
                (commission) ?
                    <div>
                        <DetailHeader
                            name={commission.employee.name}
                            date={commission.created_at}
                            status={commission.status} />
                        <Card bordered className="mb-4">
                            <Text strong>COMISSION DETAIL</Text>
                            <Row className="mb-4 mt-1">
                                <Col span={12}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <th>Employee Name</th><td>{commission.employee.name}</td>
                                            </tr>
                                            <tr>
                                                <th>Division</th><td>{commission.employee.division.division_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Position</th><td>{commission.employee.position.position_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Description</th><td>{commission.title}</td>
                                            </tr>
                                            <tr>
                                                <th>Total Amount</th><td>{toIdr(commission.total_amount)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered className="mb-4">
                            <Text strong>COMISSION ITEM</Text>
                            <Row className="mb-4 mt-1">
                                <Col span={24}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>Item Name</th>
                                                <th>Category</th>
                                                <th>Quantity</th>
                                                <th>Amount</th>
                                                <th>Attachment</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                commission.items.map((el, key) => (
                                                    <tr key={key}>
                                                        <td>{el.description}</td>
                                                        <td>{el.category ?? "-"}</td>
                                                        <td>{el.quantity ?? "-"}</td>
                                                        <td>{toIdr(el.amount)}</td>
                                                        <td>
                                                            {
                                                                (el.attachment) ?
                                                                    <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + el.attachment}>
                                                                        show attachment
                                                                    </a> : '-'
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan={3} id="total">Total</th>
                                                <td >{toIdr(commission.total_amount)}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered>
                            <ApprovalLog category="COMMISSION"
                                requestId={commission.sub_commission_id}
                                approvers={commission.approver}
                                onSubmitted={() => statusUpdated()} />
                        </Card>
                    </div> : <Spinner />
            }

        </Card>
    );
}

export default Detail;
